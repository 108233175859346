import Button from '@mui/material/Button';
import { useLanguage } from '../../../language/useLanguage';
import { LazyImage } from '../../../assets/image/lazyImage';
import { useAuthContext } from '../../../context/authContext';
import { useNavigate } from 'react-router-dom';
import './item-list.css';
import { GetDiscount } from '../../../config/common-function/get-discount/get-discount';
import { useSelector } from 'react-redux';
import IconAdded from '../../../assets/icons/icon-common/icon-added';
import { Fragment, useState } from 'react';

export default function ItemCard({ data, handleAddToCart, model_id, path }) {
  const langData = useLanguage();
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const navigate = useNavigate();
  const cart_items = useSelector((state) => state.cart.cartItems);
  const inCart = isAuth && cart_items?.length > 0 ? cart_items?.some((val) => val.item_id === data.item_id && val.CustomerTractor.TractorModel.id === model_id) : false;

  return (
    <div className="card">
      <div className={`item-card ${!isAuth ? '' : data.isItemAvailable ? '' : 'not_avilable'}`}>
        <div className="item-image">
          <LazyImage name={data.item_img} fallbackImage="tractor-model-image-rect.svg" height={'100%'} width={'100%'} />
        </div>
        <div className="content">
          <h3>{data.Item.item_name}</h3>
          {isAuth ? (
            <div className="prices">
              <div className="offer-price">₹ {data.Item.offer_total}</div>
              <div className="mrp-price">₹ {data.Item.mrp_total}</div>
              <GetDiscount mrp={data.Item.mrp_total} offer={data.Item.offer_total} />
            </div>
          ) : (
            ''
          )}
          {isAuth ? (
            <Button
              variant={'contained'}
              disabled={!data?.isItemAvailable || inCart}
              onClick={() => handleAddToCart(data.item_id)}
              sx={{
                ...((!data?.isItemAvailable || inCart) && {
                  '&&.MuiButton-root.MuiButtonBase-root.Mui-disabled': {
                    backgroundColor: '#002852',
                    color: '#fff',
                  },
                }),
              }}
              startIcon={inCart && <IconAdded />}
            >
              {inCart ? langData('Added') : langData('Add to Cart')}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() =>
                navigate('/login', {
                  state: {
                    redirectTo: `${path}`,
                  },
                })
              }
            >
              {langData('Login For Best Price')}
            </Button>
          )}
        </div>
      </div>
      {!data.isItemAvailable && isAuth ? <p className="item__unavailable">{langData('Not available at your location')}</p> : ''}
    </div>
  );
}

export function KitCard({ data, handleAddToCart, model_id, path }) {
  const langData = useLanguage();
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const navigate = useNavigate();
  const cart_items = useSelector((state) => state.cart.cartItems);
  const inCart = isAuth && cart_items?.length > 0 ? cart_items?.some((val) => val.item_id === data.item_id && val.CustomerTractor.TractorModel.id === model_id) : false;

  return (
    <div className="card" style={{ marginTop: '1rem' }}>
      <div className={`kit-card ${!isAuth ? '' : data.isItemAvailable ? '' : 'not_avilable'}`}>
        <div className="item-image">
          <LazyImage name={data.item_img} fallbackImage="tractor-model-image-rect.svg" height={'100%'} width={'100%'} />
        </div>
        <div className="content">
          <div className="heading">
            <h3>{data.Item.item_name}</h3>
            <KitItemsFunc row={data.sub_items} />
          </div>
        </div>
        <div style={{ position: 'absolute', top: '100%' }}>
          {isAuth ? (
            <div className="prices">
              <div className="offer-price">₹ {data.Item.offer_total}</div>
              <div className="mrp-price">₹ {data.Item.mrp_total}</div>
              <GetDiscount mrp={data.Item.mrp_total} offer={data.Item.offer_total} />
            </div>
          ) : (
            ''
          )}
          {isAuth ? (
            <Button
              variant={'contained'}
              disabled={!data?.isItemAvailable || inCart}
              onClick={() => handleAddToCart(data.item_id)}
              sx={{
                ...((!data?.isItemAvailable || inCart) && {
                  '&&.MuiButton-root.MuiButtonBase-root.Mui-disabled': {
                    backgroundColor: '#002852',
                    color: '#fff',
                  },
                }),
              }}
              startIcon={inCart && <IconAdded />}
            >
              {inCart ? langData('Added') : langData('Add to Cart')}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() =>
                navigate('/login', {
                  state: {
                    redirectTo: `${path}`,
                  },
                })
              }
            >
              {langData('Login For Best Price')}
            </Button>
          )}
        </div>
      </div>
      {!data.isItemAvailable && isAuth ? <p className="item__unavailable">{langData('Not available at your location')}</p> : ''}
    </div>
  );
}
function KitItemsFunc({ row }) {
  const [expanded, setExpanded] = useState(false);
  const length = 40;
  const fullText = row.map((item) => `${item.quantity} x ${item.item_name}`).join(', ');
  const isOverflow = fullText.length > length;

  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <div style={{ padding: '16px 0px' }}>
      <p style={{ whiteSpace: 'pre-line', color: '#6d7d93', fontWeight: 500, lineHeight: '20px' }}>
        {isOverflow ? (expanded ? fullText : `${fullText.slice(0, length)}...`) : fullText}
      </p>
      {isOverflow && (
        <p
          onClick={toggleExpand}
          style={{
            color: '#EC2726',
            fontSize: 13,
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
        >
          {expanded ? '- Show Less' : '+ Show More'}
        </p>
      )}
    </div>
  );
}
