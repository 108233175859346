import { Button, styled, Tab, Tabs } from '@mui/material';
import './processworks.css';
import { useContext, useState } from 'react';
import { SectionTagTransparent } from '../../../../common/tags';
import IconArrowRedirect from '../../../../../assets/icons/icon-common/icon-redirection';
import { useSelector } from 'react-redux';
import { promptContext, promptActions } from '../../../../../context/prompt';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../../language/useLanguage';

export default function OurProcess({ data, cta }) {
  const [activeTab, setActiveTab] = useState('1');
  const modelId = useSelector((state) => state.customer_order.tractorModelId);
  const brandId = useSelector((state) => state.customer_order.tractorBrandID);
  const prompt = useContext(promptContext);
  const navigate = useNavigate();
  const langData = useLanguage();

  const handleTabChange = (event) => {
    if (event.target?.id) {
      setActiveTab(event.target.id);
    }
  };

  const handelScroll = () => {
    if (!modelId || !brandId) {
      const serviceSetion = document.getElementsByClassName('service-selection-container')[0];

      serviceSetion.scrollIntoView({
        inline: 'start',
        block: 'start',
        behavior: 'smooth',
      });

      if (!brandId) {
        //here we have used the service-select window variable
        window.brandModelFormFocus('tractor_brand_id');
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: 'Please select Brand',
          },
        });
      } else if (!modelId) {
        window.brandModelFormFocus('tractor_model_id');
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: 'Please select Model',
          },
        });
      }
    }
    if (modelId && brandId) {
      const navigateLink = modelId && brandId ? '/customer-order' : '/';
      navigate(navigateLink);
    }
  };

  return (
    <>
      <OurProcessContainer>
        <OurProcessWrapper>
          <ImageSection className="image_section">
            <SectionTagTransparent text={data.tag} />
            <h3>{data.heading}</h3>
            <p
              style={{
                color: '#FFFFFF99',
                fontSize: 18,
                fontWeight: 400,
              }}
            >
              {data.sub_heading}
            </p>
            <div className="gif-section">
              {activeTab === '1' ? (
                <img className="gif-new" src={data?.book_service_video_section?.video_link} alt="process" />
              ) : (
                <img className="gif-new" src={data?.buy_product_video_section?.video_link} alt="process" />
              )}
            </div>
            {/* <img src="./assets/images/tractor-bg-gif.svg" alt="" /> */}
          </ImageSection>
          <div className="process_content" style={{ flex: '1 1 500px' }}>
            <Tabs value={activeTab} onClick={handleTabChange}>
              <Tab label={langData('Book Service')} id="1" value={'1'} className="newtab" />
              <Tab label={langData('Buy Products')} id="2" value={'2'} className="newtab" />
            </Tabs>
            <CustomTabPanel value={activeTab} index="1" className="booking-panel">
              <ProcessDetailsContainer>
                {data.book_service?.map((elem, num) => (
                  <ProcessCard key={num + 1} title={elem.title} subtitle={elem.description} prcnumber={elem.no} />
                ))}
              </ProcessDetailsContainer>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index="2" className="booking-panel">
              <ProcessDetailsContainer>
                {data.buy_products?.map((elem, num) => (
                  <ProcessCard key={num + 1} title={elem.title} subtitle={elem.description} prcnumber={elem.no} />
                ))}
              </ProcessDetailsContainer>
            </CustomTabPanel>
          </div>
        </OurProcessWrapper>
      </OurProcessContainer>
      <BannerSectionContainer style={{ position: 'relative' }}>
        <div className="banner_content">
          <h3>{cta.heading}</h3>
          <p>{cta.sub_heading}</p>
          <Button
            variant="contained"
            endIcon={<IconArrowRedirect color="#fff" height="0.6rem" width="0.6rem" />}
            style={{
              fontWeight: '400',
              fontSize: 14,
              height: 42,
              padding: '4px 18px',
            }}
            onClick={() => navigate('/franchise')}
          >
            {langData('Inquire Now')}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 8,
            flexWrap: 'wrap',
            // position: "relative",
          }}
        >
          <img src="./images/men-image-cta-without-shadow.png" className="img-one-cta" />
          <img src="./images/tractor-image-cta.png" className="img-two-cta" />
        </div>
      </BannerSectionContainer>
    </>
  );
}

function ProcessCard({ title, subtitle, prcnumber }) {
  return (
    <ProcessCardContainer>
      <ol style={{ color: '#6D7D93', margin: 0, padding: 0 }}>{prcnumber}</ol>
      <ProcessCardDecContainer>
        <h4
          style={{
            color: '#fff',
            fontSize: '22px',
            fontWeight: 500,
          }}
        >
          {title}
        </h4>
        <p
          style={{
            color: '#6D7D93',
            fontSize: 18,
            lineHeight: '1.4rem',
            fontWeight: 400,
          }}
        >
          {subtitle}
        </p>
      </ProcessCardDecContainer>
    </ProcessCardContainer>
  );
}

const OurProcessContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'var(--clr-primary)',
  padding: '4rem var(--padding-inline) 12rem',
  marginInline: 'calc(0px - var(--padding-inline))',
});

const OurProcessWrapper = styled('div')({
  display: 'flex',
  gap: '5rem',
  flexWrap: 'wrap',
  alignItems: 'center',
  ' div.process_content': {
    ' .MuiTabs-root .MuiTabs-indicator': {
      // backgroundColor: "#fff"
    },
  },
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    ' div.process_content': {
      flexBasis: 500,
    },
  },
  ' @media only screen and (max-width: 600px)': {
    gap: '3rem',
  },
});

const ImageSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  // flexBasis: "50%",
  flex: '1 1 500px',
  h3: {
    color: '#fff',
    fontSize: '3rem',
    fontWeight: 500,
  },
  gap: 16,
  ' div.gif-section': {
    background: 'no-repeat url(/assets/images/gif-background.png)',
    backgroundSize: '90%',
    backgroundPosition: 'center bottom',
    width: '100%',
    margin: '2rem auto 0',
    padding: '1rem 0',
    display: 'flex',
    'img.gif-new': {
      height: '100%',
      width: '35%',
      margin: '0 auto',
    },
  },
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    flexBasis: 500,
    ' div.gif-section': {
      backgroundSize: '100%',
      backgroundPosition: 'center bottom',
      display: 'flex',
      'img.gif-new': {
        height: '100%',
        width: '45%',
        margin: '0 auto',
      },
    },
  },
  ' @media only screen and (max-width: 600px)': {
    img: {
      margin: '0 auto',
    },
    'img.gif-new': {
      height: 320,
      width: 320,
    },
    h3: {
      fontSize: '2rem',
    },
    p: {
      fontSize: '1rem',
    },
  },
});

const ProcessDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2.9rem',
  flex: '1 1 500px',
  marginTop: 24,
  marginBottom: 24,
});

const ProcessCardContainer = styled('div')({
  borderRadius: '7px',
  display: 'flex',
  gap: '1.5rem',
  // marginTop: 22,
  alignItems: 'baseline',
});

const ProcessCardDecContainer = styled('div')({
  gap: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
});

const BannerSectionContainer = styled('div')({
  backgroundImage: 'url(./images/red-background-cta.png)',
  padding: '46px',
  borderRadius: 8,
  minHeight: '300px',
  display: 'flex',
  alignItems: 'center',
  backgroundSize: 'cover',
  justifyContent: 'space-between',
  p: { color: '#ffffffCC', fontSize: 18, fontWeight: 400 },
  h3: { color: '#fff', fontSize: 32, fontWeight: 600 },
  marginTop: '-7rem',
  ' div.banner_content': {
    flexBasis: 400,
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  ' .MuiButton-root.MuiButtonBase-root': {
    width: 'fit-content',
  },
  ' @media only screen and (max-width: 600px)': {
    backgroundImage: 'url(./images/red-backgroud-cta-responsive.png)',
    alignItems: 'normal',
    padding: '34px',
    minHeight: '450px',
    h3: { color: '#fff', fontSize: 28, fontWeight: 600 },
  },
  '@media screen and (min-width: 750px) and (max-width: 1300px)': {
    ' div.banner_content': {
      flexBasis: 400,
    },
  },
});

// const BannerDetails = styled("div")({
//   display: "flex",
//   flexDirection: "column",
//   gap: 16,
//   alignItems: "flex-start",
//   p: { color: "#fff" },
//   h3: { color: "#fff" },
// });

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      // style={{ minHeight: "38vh" }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
