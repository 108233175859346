import BannerCarousal from './components/home-page-sections/home-page-banner/banner-carousel';
import { useAuthContext } from '../../context/authContext';
import { useGetBrand, useGetHomepageContent, useGetModel } from './homepage-api';
import HomePageSEO from './homepage-seo';
import { ShopByCategory as Category } from './components/home-page-sections/shop-by-category/shop-by-category';
import OurOffering from './components/home-page-sections/our-offering/our-offering';
import OurProcess from './components/home-page-sections/home-page-process-V2/process-works';
import AboutUs from './components/home-page-sections/home-page-about-us/about-us';
import OurBenefits from './components/home-page-sections/home-page-our-benefits/our-benefits';
import { promptActions, promptContext } from '../../context/prompt';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTractorBrandId, setTractorModelId } from '../../redux/features/customer-order/customerOrderSlice';

export default function HomepageV2() {
  const dispatch = useDispatch();
  if (localStorage.getItem('tractor_brand')) {
    const tractor_brand = localStorage.getItem('tractor_brand') ? JSON.parse(localStorage.getItem('tractor_brand')) : null;

    if (tractor_brand) {
      dispatch(setTractorBrandId(tractor_brand?.brand_id ?? null));
    }
  }

  if (localStorage.getItem('tractor_model')) {
    const tractor_model = localStorage.getItem('tractor_model') ? JSON.parse(localStorage.getItem('tractor_model')) : null;
    if (tractor_model) {
      dispatch(setTractorModelId(tractor_model?.model_id ?? null));
    }
  }
  const modelId = useSelector((state) => state.customer_order.tractorModelId);
  const tractorBrandId = useSelector((state) => state.customer_order.tractorBrandID);

  useEffect(() => {
    if (tractorBrandId === null) {
      dispatch(setTractorModelId(null));
      window.scrollTo(0, 0);
    }
  }, [tractorBrandId, dispatch]);
  const brandList = useGetBrand();

  const modelList = useGetModel(tractorBrandId);
  const { getData } = useAuthContext();
  const { isAuth, lang } = getData();
  const { data, isLoading } = useGetHomepageContent(isAuth, lang);

  const brandId = useSelector((state) => state.customer_order.tractorBrandID);
  const workshopSelectId = useSelector((state) => state.location.workshopSelectId);
  const prompt = useContext(promptContext);

  const handelScroll = () => {
    if (!modelId || !brandId) {
      const serviceSetion = document.getElementsByClassName('service-selection-container')[0];

      serviceSetion.scrollIntoView({
        inline: 'start',
        block: 'start',
        behavior: 'smooth',
      });

      if (!brandId) {
        //here we have used the service-select window variable
        window.brandModelFormFocus('tractor_brand_id');
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: 'Please select Brand',
          },
        });
      } else if (!modelId) {
        window.brandModelFormFocus('tractor_model_id');
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: 'Please select Model',
          },
        });
      }
    }
  };

  if (isLoading) return <p>Loading...</p>;
  console.debug(workshopSelectId, 'workshopSelectId check');
  return (
    <div className="home">
      <HomePageSEO />
      <BannerCarousal data={data?.image_section ?? []} />
      <OurOffering data={data?.offerings_section ?? {}} handelScroll={handelScroll} brandList={brandList} modelList={modelList} modelId={modelId} tractorBrandId={tractorBrandId} />
      <Category data={data?.categories_section ?? {}} handelScroll={handelScroll} brandList={brandList} modelList={modelList} modelId={modelId} tractorBrandId={tractorBrandId} />
      <OurProcess data={data?.process_section ?? {}} cta={data?.book_service_section ?? {}} />
      <AboutUs data={data?.about_us_section ?? {}} />
      <OurBenefits data={data?.about_us_section ?? {}} />
    </div>
  );
}
